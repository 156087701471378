<div class="py-5"
  style="background-image:linear-gradient(#ff7c0a3d, #ff7c0a3c),url('assets/images/login-image.jpg');background-repeat:no-repeat;background-size:cover;height: 100vh;">
  <div class="container pt-lg-md">
    <div class="row justify-content-center">
      <div class="col-lg-5">
        <div class="card" style="background-color: rgba(254, 254, 254, 0.733);box-shadow: 5px 5px 8px 5px #3f3e3e51;">
          <div class="card-body p-5">
            <div class="text-center">
              <h3 class="font-weight-bold">
                <a href="/" class="text-dark text-uppercase account-pages-logo">
                  <img src="assets/images/logo.png" alt="" class="img-fluid">
                  <!-- <img src="assets/images/cs-pma.png" alt="" class="img-fluid"> -->
                </a>
              </h3>
              <p class="text-muted" style="font-weight:bold">Sign in to continue</p>
            </div>
            <div class="p-3">
              <div *ngIf="errorMessage" class="alert alert-danger">{{errorMessage}}</div>
              <form method="POST" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                  <label for="emailId">Email Id</label>
                  <input type="email" formControlName="emailId" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }" tabindex="1" />
                  <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
                    <div *ngIf="f.emailId.errors.required">Username is required</div>
                    <div *ngIf="f.emailId.errors.pattern">Email must be a valid email address</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="password">Password
                    <!-- <a routerLink="/forgot-password" class="float-right">
                        Forgot Password?
                      </a> -->
                  </label>
                  <input type="password" formControlName="password" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" tabindex="2" />
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                  </div>
                </div>

                <!-- <div class="form-group">
                  <div class="custom-checkbox custom-control">
                    <input type="checkbox" name="remember" id="remember" class="custom-control-input">
                    <label for="remember" class="custom-control-label">Remeber Me</label>
                  </div>
                </div> -->

                <div class="form-group m-0">
                  <button type="submit" class="btn btn-warning btn-block btn-round">
                    Login
                  </button>
                </div>
                <!-- <div class="mt-4 text-center f14">
                    Don't have an account? <a routerLink="/register">Create One</a>
                  </div> -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>