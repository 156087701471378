import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize,tap } from "rxjs/operators";
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(public spinner: NgxSpinnerService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // ! sir code 
    //   this.spinner.show()
  //   return next.handle(request).pipe(
  //     finalize(() => this.spinner.hide())
  //   );
  // }
  this.spinner.show();
  return this.handler(next,request);
 }
 handler(next: HttpHandler , request: HttpRequest<unknown>){
 
  return next.handle(request)
  .pipe(
    tap(
      (event) => {
        
        if(event instanceof HttpResponse) {
          this.spinner.hide();
        }
      },
      (error : HttpErrorResponse) => {
        this.spinner.hide();
        throw error;
      }
    )
  )
 }
}