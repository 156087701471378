import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { RoleType } from '../admin/core/models/role.enum';
import { AuthenticationService } from '../core/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm!: FormGroup;
  submitted = false;
  emailPattern: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  errorMessage!: string;
  userInfo: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private authService: AuthenticationService,
  ) {
    // redirect to home if already logged in
    // if (this.authService.currentUser) {
    //   this.router.navigate(['/admin']);
    // }
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      emailId: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      password: ['', Validators.required]
    });
  }
  // getting form fields 

  get f() { return this.loginForm.controls; }

  //  submitting form data 

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.get('emailId')?.value, this.loginForm.get('password')?.value).subscribe(
        data => {
          if (data.userObject == null) {
            this.errorMessage = "Invalid Credentials";
            setTimeout(() => {
              this.errorMessage = "";
            }, 2000);
          } else {
            localStorage.setItem('userId', data.userObject.userId);
            localStorage.setItem('currentUserId', data.userObject.userId);
            localStorage.setItem('currentUserInfo', JSON.stringify(data.userObject));
            this.spinner.show();

            this.router.navigate(['admin/dashboard']);
            setTimeout(() => {
              /** spinner ends after 5 seconds */
              this.spinner.hide();
            }, 2000);
          }

        },
        _error => {
          this.errorMessage = "Invalid Credentials";
          this.spinner.hide();
        }
      )
    }
    else {
      this.router.navigate(['/login'])
    }

  }

}
