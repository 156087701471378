import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrentUser } from 'src/app/admin/core/models/user';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<CurrentUser>;
  public user: Observable<CurrentUser>;

  constructor(
    private http: HttpClient,
    private tokenService: TokenService
  ) {
    this.currentUserSubject = new BehaviorSubject<CurrentUser>(JSON.parse(localStorage.getItem('currentUser')!));
    this.user = this.currentUserSubject.asObservable();
  }

  public get currentUser(): CurrentUser {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this.http.post<any>(`user/login`, { email: username, password: password }).pipe(
      map(data => {

        this.setAuth(data);
        return data;
        
      })
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null!);
    this.purgeAuth();
  }
  setAuth(user: CurrentUser) {
    localStorage.setItem('currentUser', JSON.stringify(user));
   // localStorage.setItem('userId',user.dataObject.userId)
    this.currentUserSubject.next(user);
    // Save JWT sent from server in localstorage
    this.tokenService.saveToken(user.token);
  }
  purgeAuth() {
    this.tokenService.destroyToken();
  }
}
